import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from '../../../../shared/constants/ActionTypes';
import jwtAxios, {setAuthToken} from './jwt-api';

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({children}) => {
  const [jwtAuthData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem('token');

      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      jwtAxios
        .get('v1/me')
        .then(({data}) => {
            //console.log(" jwtAxios data ->", data.data);
            if(data.data.group_id == 7) {
              setJWTAuthData({
                user: data.data,
                isLoading: false,
                isAuthenticated: true,
              })
            }else {
              setJWTAuthData({
                user: undefined,
                isLoading: false,
                isAuthenticated: false,
              })
              //window.location.replace("https://dam.huayjaiwai.com");
            }

        })
        .catch(() =>
          setJWTAuthData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false,
          }),
        );
    };

    getAuthUser();
  }, []);

  const signInUser = async ({username, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('v1/login', {username, password});
      localStorage.setItem('token', data.token);
      setAuthToken(data.token);
      const res = await jwtAxios.get('v1/me');

      //console.log("signInUser -> data --> ", res.data);

      if( res.data.data.group_id == 7) {
        setJWTAuthData({user: res.data.data, isAuthenticated: true, isLoading: false});
        dispatch({type: FETCH_SUCCESS});
      } else {
        localStorage.setItem('token', "");
        setAuthToken("");
        setJWTAuthData({
          user: null,
          isAuthenticated: false,
          isLoading: false,
        });
        dispatch({type: FETCH_ERROR, payload: "ไม่มีสิทธิ์เข้าใช้งาน"});
      }
    } catch (error) {

      console.log("error -->", error);

      setJWTAuthData({
        user: null,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง"});
    }
  };

  const signUpUser = async ({name, email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('users', {name, email, password});
      localStorage.setItem('token', data.token);
      setAuthToken(data.token);
      const res = await jwtAxios.get('/auth');
      setJWTAuthData({user: res.data, isAuthenticated: true, isLoading: false});
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setJWTAuthData({
        user: null,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const updateUserData = (user) => {
    setJWTAuthData({
      ...jwtAuthData,
      user: user
    });
  }

  const logout = async () => {
    localStorage.removeItem('token');
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...jwtAuthData,
      }}>
      <JWTAuthActionsContext.Provider
        value={{
          updateUserData,
          signUpUser,
          signInUser,
          logout,
        }}>
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
