import React, {useState} from 'react';
import './index.style.less';
import {Button, Col, message, Modal, Row, Spin} from "antd";
import PropTypes from "prop-types";
import {checkLottoIsClosed, displayStakeTypeBySlug, toDefaultDecimal} from "../../@crema/utility/helper/LottoHelper";
import {Link} from "react-router-dom";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import jwtAxios from "../../@crema/services/auth/jwt-auth/jwt-api";

const StakeDetailItem = (props) => {
    const {stake, isShowPlayButton} = props;
    const {lotto_round} = stake;
    const {lotto} = lotto_round;
    const [stakeItem, setStakeItem] = useState(stake);
    const stakeListItems = stakeItem && stakeItem.lists.map((item, index) => {
        //console.log(item);
        const discount = parseFloat(item.discount);
        const isDiscount = discount > 0 ? true : false;
        let numberStatusCssClass = "number-status";
        if( item.is_win ) {
            numberStatusCssClass += " success";
        }

        let statusText = <>
            <span className="w-50 text-right text-muted mr-4">ผลได้เสีย</span>
            <h4 className="w-50 mb-0 text-muted num-zero">{ item.is_win ? toDefaultDecimal(item.pay_total) : "-"}</h4>
        </>

        if( item.is_cancel ) {
            statusText = <span className="round-cancel badge badge-lg badge-warning">ยกเลิกรอบ</span>
        } else if( item.is_return ) {
            statusText = <span className="round-cancel badge badge-lg badge-danger">ยกเลิกโพย</span>
        }


        return (
            <div className="card stake__number" key={"stake-i-"+item.id}>
                <div className="number-header"><h6 className="title">ลำดับที่ {(index+1)} : {lotto.name}</h6></div>
                <div className="number-body">
                    <div className="body-left">
                        <small className="title">เลขที่ซื้อ</small>
                        <h1 className="value">{item.number}</h1>
                        <small className="type">{displayStakeTypeBySlug(item.game_slug)}</small>
                    </div>
                    <div className="body-right">
                        <Row className="mb-3 text-center">
                            <Col flex={1} className="d-flex flex-column">
                                <small className="text-muted">เดิมพัน</small>
                                <span className="value text-primary">{parseFloat(item.price)}</span>
                            </Col>
                            {isDiscount ?
                                <Col flex={1} className="d-flex flex-column">
                                    <small className="text-muted">ส่วนลด</small>
                                    <span className="value">{discount}</span>
                                </Col>
                                : null}
                            <Col flex={1} className="d-flex flex-column">
                                <small className="text-muted">จ่าย</small>
                                <span className="value">{toDefaultDecimal(item.pay_rate)}</span>
                            </Col>
                            <Col flex={1} className="d-flex flex-column">
                                <small className="text-muted">เลขที่ออก</small>
                                <span className="value">{item.result}</span>
                            </Col>
                        </Row>
                        <div className={numberStatusCssClass}>
                            {statusText}
                        </div>
                    </div>
                </div>
            </div>
        )
    });

    const [loading, setLoading] = useState(false)

    let isClosed = false;
    if( lotto ) {
        const isOverTime = checkLottoIsClosed(lotto_round.lotto_date, lotto_round.closed_time);
        if( isOverTime || lotto_round.is_finished) {
            isClosed = true;
        }
    }

    const { confirm } = Modal;
    const showConfirm = () => {
        confirm({
            className : "custom-modal",
            title: 'ยืนยันยกเลิกโพย',
            icon: <ExclamationCircleOutlined />,
            content: 'คุณแน่ใจว่าต้องการทำงานต่อ',
            okText: 'ยืนยัน',
            cancelText: 'ปิด',
            onOk() {
                doCancelStake();
            },
            onCancel() {
                //console.log('ปิด');
            },
        });
    };

    const doCancelStake = async () => {

        try {
            setLoading(true)
            const {data} = await jwtAxios.post("/v1/member/stake/cancel/" + stake.id, {});
            setLoading(false)
            if( data.code != 200 && data.message != "success") {
                message.error({
                    content: data.message,
                    className: 'custom-message-error',
                });
                return;
            }
            message.success({
                content: "Success",
                className: 'custom-message-success',
            });
            //console.log(data);
            setStakeItem(data.data);
            // window.location.reload(true);
            //this.props.success();
        } catch (error) {
            setLoading(false)
            //console.log(error.message)
            message.error({
                content: error.message,
                className: 'custom-message-error',
            });
        }
    }

    let winCssClass = "value";
    if( stakeItem.win_total > 0 ) {
        winCssClass += " success";
    }

    const renderButtonZone = () => {
        return (
            <>
                { ! isClosed && isShowPlayButton && ! stakeItem.is_return && ! stakeItem.is_cancel ?
                    <Row className="row row-sm mb-4" gutter={6}>
                        <Col span={6}>
                            <Button style={{width:"100%", borderRadius:20}}>
                                <Link to="/stake/list">ดูโพย</Link>
                            </Button>
                        </Col>
                        <Col span={18}>
                            <Button type="primary"  style={{width:"100%", borderRadius:20}}>
                                <Link to={"/lotto/" + lotto_round.id}>แทงต่อ</Link>
                            </Button>
                        </Col>
                    </Row>
                    : null}

                { ! isClosed & ! isShowPlayButton & ! stakeItem.is_return & ! stakeItem.is_cancel?
                    <Row className="row row-sm mb-4">
                        <Col span={24}>
                            <Button type="danger" style={{width:"100%", borderRadius:20}} onClick={showConfirm}>ยกเลิกโพย</Button>
                        </Col>
                    </Row>
                    : null}

                { stakeItem.is_return & ! stakeItem.is_cancel ?
                    <Row className="row row-sm mb-4">
                        <Col span={24}>
                            <Button  className="stake-btn disabled" style={{width:"100%", borderRadius:20}}>ยกเลิกเรียบร้อยแล้ว</Button>
                        </Col>
                    </Row> : null }

            </>
        )
    }

    return (

        <Spin spinning={loading}>
            <div className="custom-container col-no-mb">

                <div className="card stake__detail mt-4 mb-4">
                    <div className="detail-header"><h4 className="title">เลขที่รายการ {stakeItem.id}</h4></div>
                    <Row className="row row-sm">
                        <Col span={12} className="col-6 detail-item">
                            <h5 className="title">แทงรวม</h5>
                            <h1 className="value">{toDefaultDecimal(stakeItem.total)}</h1>
                        </Col>
                        <Col span={12} className="col-6 detail-item">
                            <h5 className="title">ผลชนะทั้งสิ้น</h5>
                            <h1 className={winCssClass}>{ stakeItem.win_total > 0 ? toDefaultDecimal(stakeItem.win_total) : "-"}</h1>
                        </Col>
                    </Row>
                </div>

                {renderButtonZone()}

                <div className="mt-4">
                    {stakeListItems}
                </div>

            </div>
        </Spin>
    )
}

export default StakeDetailItem;

StakeDetailItem.propTypes = {
    stake: PropTypes.any,
    isShowPlayButton: PropTypes.bool,
};

StakeDetailItem.defaultProps = {};