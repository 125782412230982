import React from 'react';
import {Layout} from 'antd';
import './index.style.less';
import AppLogo from '../components/AppLogo';
// import AppHorizontalNav from '../components/AppHorizontalNav';
// import { AiOutlineMenu } from 'react-icons/ai';
import PropTypes from 'prop-types';
import UserInfo from '../components/UserInfo';
// import { useSidebarContext } from '../../../utility/AppContextProvider/SidebarContextProvider';
import { FaChevronLeft, FaHome} from "react-icons/fa";


const AppHeader = () => {
  const { Header } = Layout;
  // const { sidebarColorSet } = useSidebarContext();
  const pathname = window.location.pathname;
  let backIcon = <FaChevronLeft />
  let isHome = false;
  if( pathname.indexOf("home") !== -1) {
      isHome = true;
      backIcon = <FaHome />
  }

  //console.log("AppHeader --> pathname --> ", pathname);

  return (
    <Header className='app-header-hor'>

      <div className='header-hor-main'>
        {/*<div className='container'> */}
          <div className='header-hor-main-flex ccc'>
            {/* <a className='trigger' onClick={showDrawer}>
              <AiOutlineMenu />
            </a> */}
            <div className="my-back-icon" onClick={() => {
               if( ! isHome ) {
                   window.history.back()
               }
            }}>
                {backIcon}
            </div>
            <AppLogo />
            <UserInfo />
          </div>
        {/*</div>*/}
      </div>

      {/* <div
        className='header-nav-hor'
        style={{
          backgroundColor: sidebarColorSet.sidebarBgColor,
          color: sidebarColorSet.sidebarTextColor,
        }}>
        <div className='container'>
          <AppHorizontalNav className='app-main-hor-menu' />
        </div>
      </div> */}
    </Header>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  showDrawer: PropTypes.func,
};
