import React from 'react';
import {FaAward, FaFileInvoiceDollar, FaHome, FaReceipt} from "react-icons/fa";
import {BellSchool} from "tabler-icons-react";
import PropTypes from "prop-types";
import './index.style.less';

const PageHeader = (props) => {

    const pathname = window.location.pathname;
    let backIcon = <FaHome className="icon" />
    if( pathname.indexOf("result") !== -1) {
        backIcon = <FaAward  className="icon" />
    } else if( pathname.indexOf("lotto") !== -1) {
        backIcon = <BellSchool className="icon" />
    } else if( pathname.indexOf("stake") !== -1) {
        backIcon = <FaReceipt className="icon" />
    } else if( pathname.indexOf("credit") !== -1) {
        backIcon = <FaFileInvoiceDollar className="icon" />
    }

    return (
        <>
            <div className="custom-page-header">
                {backIcon}
                <h1 className="title">{props.title}</h1>
                {props.extra}
            </div>
        </>
    );
};

export default PageHeader;

PageHeader.propTypes = {
    title: PropTypes.string,
    extra: PropTypes.any,
};

PageHeader.defaultProps = {};