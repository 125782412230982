import React, {useEffect, useState} from 'react';
import PageHeader from "../../../../components/PageHeader";
import StakeDetailItem from "../../../../components/StakeDetailItem";
import {useParams} from "react-router-dom";
import jwtAxios from "../../../../@crema/services/auth/jwt-auth/jwt-api";
import {Card, message, Spin} from "antd";
import {FaReceipt} from "react-icons/fa";

const StakeSuccess = () => {

    const { lottoId } = useParams();
    const [stake, setStake] = useState(null);
    const [loading, setLoading] = useState(false);
    //console.log("lottoId", lottoId);

    useEffect(async  ()=> {

        setLoading(true)
        try {
            const {data} = await jwtAxios.get('v1/game/lotto/round/' + lottoId+"/success");
            //console.log(data);
            setStake(data.data);
            setLoading(false)
        } catch (error) {
            message.info({
                content: error.message,
                className: 'custom-message-error',
            });
            setLoading(false)
        }

    },[])

    const renderEmptyElement = () => {
        return <Card className="card-empty-box mt-4">
            <div className="card-empty">
                <FaReceipt className="icon" />
                <div className="label">{loading ? "Loading..." : "ไม่มีรายการ"}</div>
            </div>
        </Card>
    }


    let stakeDetailItem = renderEmptyElement();
    let pageHeaderTitle = "";
    if( stake ) {
        pageHeaderTitle = stake.lotto_round.lotto.name;
        stakeDetailItem = <StakeDetailItem  stake={stake} isShowPlayButton={true} />
    }

    return (
        <Spin spinning={loading}>
            <PageHeader title={pageHeaderTitle} extra={[]} />
            {stakeDetailItem}
        </Spin>
    )
}

export default  StakeSuccess;