import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import Error403 from './errorPages/Error403';
import React from 'react';
import {dashboardPagesConfigs} from './dashboard';
import Error404 from './errorPages/Error404';
import {profileConfig} from './profile';
// import {loginRouteConfig} from './login';

const authorizedStructure = {
  fallbackPath: '/signin', // when user is not-autheticate then land here
  unAuthorizedComponent: <Error403 />, // when user is autheticate but not authorized then land here
  routes: [...dashboardPagesConfigs, ...profileConfig], // all authorized routed goes here
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl, // first route after login, you can change it as you want
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '*',
      element: <Error404 />,
    },
  ]),
};

export {authorizedStructure, unAuthorizedStructure, anonymousStructure};
