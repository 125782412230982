export const displayLottoClosedTime = (closedTime) => {
    return closedTime != "" ? closedTime.substring(0, 5) : "";
};

export const getLottoClosedDateTime = (date, closedTime) => {
    return date+"T"+closedTime;
};

export const checkLottoIsClosed = (date, closedTime) => {
    const closedDateTime = getLottoClosedDateTime(date, closedTime);
    const nowDate = new Date();
    const closeDate = new Date(closedDateTime);
    return nowDate > closeDate;
};

export const padWithZero = (num, targetLength) => {
    return String(num).padStart(targetLength, '0');
}

export const displayStakeTypeBySlug = (slug) => {
    if( slug == "three_top" ) {
        return "3 ตัวบน"
    } else if( slug == "three_mix" ) {
        return "3 ตัวโต๊ด"
    } else if( slug == "three_front" ) {
        return "3 ตัวหน้า"
    } else if( slug == "three_back" ) {
        return "3 ตัวหลัง"
    } else if( slug == "two_top" ) {
        return "2 ตัวบน"
    } else if( slug == "two_under" ) {
        return "2 ตัวล่าง"
    } else if( slug == "one_top" ) {
        return "วิ่งบน"
    } else if( slug == "one_under" ) {
        return "วิ่งล่าง"
    }
}

export const toDefaultDecimal = (num) => {
    return Number(parseFloat(num)).toLocaleString(undefined)

}