import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';
import StakeSuccess from "./Lotto/Success";
const Home = React.lazy(() => import('./Home'));
const LottoResult = React.lazy(() => import('./Result'));
const Lotto = React.lazy(() => import('./Lotto'));
const StakeList = React.lazy(() => import('./StakeList'));
const StakeItem = React.lazy(() => import('./StakeList/StakeItem'));
const LottoCredit = React.lazy(() => import('./Credit'));
const LottoDetail = React.lazy(() => import('./Lotto/Detail'));
const MemberProfile = React.lazy(() => import('./Member'));
const MemberSummaryReport = React.lazy(() => import('./MemberSummaryReport'));

export const dashboardPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/home',
    element: <Home />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/result',
    element: <LottoResult />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/lotto',
    element: <Lotto />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/lotto/:lottoId',
    element: <LottoDetail />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/lotto/:lottoId/success',
    element: <StakeSuccess />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/stake/list',
    element: <StakeList />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/stake/list/:lottoId/:stakeId',
    element: <StakeItem />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/credit',
    element: <LottoCredit />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/report',
    element: <MemberSummaryReport />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/member/profile',
    element: <MemberProfile />,
  },
];