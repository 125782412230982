import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'antd';
import './index.style.less';
import {FaAward, FaFileInvoiceDollar, FaHome, FaReceipt} from "react-icons/fa";
import {BellSchool, ChartInfographic} from "tabler-icons-react";

const AppFooterMenu = () => (

    <div className="footer-nav">
        <div className="footer-nav-item">
            <Button type="link" block>
                <NavLink to="home" activeclassname="active">
                    <FaHome />
                    <span>หน้าแรก</span>
                </NavLink>
            </Button>
        </div>
        <div className="footer-nav-item">
            <Button type="link" block>
                <NavLink to="result" activeclassname="active">
                    <FaAward />
                    <span>ผลรางวัล</span>
                </NavLink>
            </Button>
        </div>
        <div className="footer-nav-item">
            <Button type="link" block>
                <NavLink to="lotto" activeclassname="active">
                    <BellSchool />
                    <span>แทงหวย</span>
                </NavLink>
            </Button>
        </div>
        <div className="footer-nav-item">
            <Button type="link" block>
                <NavLink to="stake/list" activeclassname="active">
                    <FaReceipt />
                    <span>โพยหวย</span>
                </NavLink>
            </Button>
        </div>
        <div className="footer-nav-item">
            <Button type="link" block>
                <NavLink to="credit" activeclassname="active">
                    <FaFileInvoiceDollar />
                    <span>เครดิต</span>
                </NavLink>
            </Button>
        </div>
        <div className="footer-nav-item">
            <Button type="link" block>
                <NavLink to="report" activeclassname="active">
                    <ChartInfographic />
                    <span>เคลียร์ยอด</span>
                </NavLink>
            </Button>
        </div>
    </div>
);

export default AppFooterMenu;